/* Settings
---------------------------------------------------------------------------- */
// Breakpoints
$breakpoints: (
  xs: 568px,
  sm: 800px,
  md: 1000px,
  lg: 1200px,
  xl: 1650px
);

$debug: false;

// Hover classes
$touch-class:    'touch';
$no-touch-class: 'no-touch';

// Margins steps & sizes
// Calc: $margin-size * $margin-steps = {suffix} from m[] and p[] classes
$margin-size:  5px !default;
$margin-steps: 50 !default;

// Grid
$grid-columns:    12 !default;
$grid-gutter:     ( sm: 20px );
$min-grid-gutter: 14px !default;

/* END: Settings
---------------------------------------------------------------------------- */

/* Easing
---------------------------------------------------------------------------- */
$ease: (
  in-quad:      cubic-bezier(0.550,  0.085, 0.680, 0.530),
  in-cubic:     cubic-bezier(0.550,  0.055, 0.675, 0.190),
  in-quart:     cubic-bezier(0.895,  0.030, 0.685, 0.220),
  in-quint:     cubic-bezier(0.755,  0.050, 0.855, 0.060),
  in-sine:      cubic-bezier(0.470,  0.000, 0.745, 0.715),
  in-expo:      cubic-bezier(0.950,  0.050, 0.795, 0.035),
  in-circ:      cubic-bezier(0.600,  0.040, 0.980, 0.335),
  in-back:      cubic-bezier(0.600, -0.280, 0.735, 0.045),
  out-quad:     cubic-bezier(0.250,  0.460, 0.450, 0.940),
  out-cubic:    cubic-bezier(0.215,  0.610, 0.355, 1.000),
  out-quart:    cubic-bezier(0.165,  0.840, 0.440, 1.000),
  out-quint:    cubic-bezier(0.230,  1.000, 0.320, 1.000),
  out-sine:     cubic-bezier(0.390,  0.575, 0.565, 1.000),
  out-expo:     cubic-bezier(0.190,  1.000, 0.220, 1.000),
  out-circ:     cubic-bezier(0.075,  0.820, 0.165, 1.000),
  out-back:     cubic-bezier(0.175,  0.885, 0.320, 1.275),
  in-out-quad:  cubic-bezier(0.455,  0.030, 0.515, 0.955),
  in-out-cubic: cubic-bezier(0.645,  0.045, 0.355, 1.000),
  in-out-quart: cubic-bezier(0.770,  0.000, 0.175, 1.000),
  in-out-quint: cubic-bezier(0.860,  0.000, 0.070, 1.000),
  in-out-sine:  cubic-bezier(0.445,  0.050, 0.550, 0.950),
  in-out-expo:  cubic-bezier(1.000,  0.000, 0.000, 1.000),
  in-out-circ:  cubic-bezier(0.785,  0.135, 0.150, 0.860),
  in-out-back:  cubic-bezier(0.680, -0.550, 0.265, 1.550)
);

@function ease($key) {
  @if map-has-key($ease, $key) {
    @return map-get($ease, $key);
  }

  @warn "Unkown '#{$key}' in $ease.";
  @return null;
}

/* Hover for touch and no-touch deveices
---------------------------------------------------------------------------- */
@mixin hover {
  .#{$touch-class} &:active,
  .#{$no-touch-class} &:hover {
    @content;
  }
}

/* from() & to() responsive mixins
---------------------------------------------------------------------------- */
@mixin from($breakpoint) {
  @if variable-exists(breakpoints) {
    @if map-has-key($breakpoints, $breakpoint) {
      $width: map-get($breakpoints, $breakpoint);

      @media only screen and (min-width: #{$width}) {
        @content;
      }
    } @else {
      @warn "Unkown '#{$breakpoint}' in $breakpoints.";
    }
  } @else {
    @warn "Undefined breakpoints";
  }
}

@mixin to($breakpoint) {
  @if variable-exists(breakpoints) {
    @if map-has-key($breakpoints, $breakpoint) {
      $width: map-get($breakpoints, $breakpoint);

      @media only screen and (max-width: #{$width + 1}) {
        @content;
      }
    } @else {
      @warn "Unkown '#{$breakpoint}' in $breakpoints.";
    }
  } @else {
    @warn "Undefined breakpoints";
  }
}

/* Margins
---------------------------------------------------------------------------- */

/* Function for remove units */
@function _strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@for $i from 1 through $margin-steps {
  .m-auto { margin: auto; }
  .mt-auto { margin-top: auto; }

  .mx-no {
    margin-left: 0;
    margin-right: 0;
  }
  .my-no {
    margin-top: 0;
    margin-bottom: 0;
  }
  .mx-#{$i * _strip-unit($margin-size)} {
    margin-left: $margin-size * $i;
    margin-right: $margin-size * $i;
  }
  .my-#{$i * _strip-unit($margin-size)} {
    margin-top: $margin-size * $i;
    margin-bottom: $margin-size * $i;
  }

  .mb-no { margin-bottom: 0; }
  .mt-no { margin-top: 0; }
  .mr-no { margin-right: 0; }
  .ml-no { margin-left: 0; }

  .mb-#{$i * _strip-unit($margin-size)} { margin-bottom: $margin-size * $i; }
  .mt-#{$i * _strip-unit($margin-size)} { margin-top: $margin-size * $i; }
  .mr-#{$i * _strip-unit($margin-size)} { margin-right: $margin-size * $i; }
  .ml-#{$i * _strip-unit($margin-size)} { margin-left: $margin-size * $i; }

  @each $name, $size in $breakpoints {
    @include from($name) {
      .#{$name}\:m-auto { margin: auto; }
      .#{$name}\:mt-auto { margin-top: auto; }

      .#{$name}\:mx-no {
        margin-left: 0;
        margin-right: 0;
      }
      .#{$name}\:my-no {
        margin-top: 0;
        margin-bottom: 0;
      }
      .#{$name}\:mx-#{$i * _strip-unit($margin-size)} {
        margin-left: $margin-size * $i;
        margin-right: $margin-size * $i;
      }
      .#{$name}\:my-#{$i * _strip-unit($margin-size)} {
        margin-top: $margin-size * $i;
        margin-bottom: $margin-size * $i;
      }

      .#{$name}\:mb-no { margin-bottom: 0; }
      .#{$name}\:mt-no { margin-top: 0; }
      .#{$name}\:ml-no { margin-right: 0; }
      .#{$name}\:mr-no { margin-left: 0; }
      
      .#{$name}\:mb-#{$i * _strip-unit($margin-size)} { margin-bottom: $margin-size * $i; }
      .#{$name}\:mt-#{$i * _strip-unit($margin-size)} { margin-top: $margin-size * $i; }
      .#{$name}\:mr-#{$i * _strip-unit($margin-size)} { margin-right: $margin-size * $i; }
      .#{$name}\:ml-#{$i * _strip-unit($margin-size)} { margin-left: $margin-size * $i; }
    }
  }
}

/* Padding
---------------------------------------------------------------------------- */
@for $i from 1 through $margin-steps {
  .px-no {
    padding-left: 0;
    padding-right: 0;
  }
  .py-no {
    padding-top: 0;
    padding-bottom: 0;
  }
  .px-#{$i * _strip-unit($margin-size)} {
    padding-left: $margin-size * $i;
    padding-right: $margin-size * $i;
  }
  .py-#{$i * _strip-unit($margin-size)} {
    padding-top: $margin-size * $i;
    padding-bottom: $margin-size * $i;
  }
  .pb-#{$i * _strip-unit($margin-size)} { padding-bottom: $margin-size * $i; }
  .pt-#{$i * _strip-unit($margin-size)} { padding-top: $margin-size * $i; }
  .pr-#{$i * _strip-unit($margin-size)} { padding-right: $margin-size * $i; }
  .pl-#{$i * _strip-unit($margin-size)} { padding-left: $margin-size * $i; }

  @each $name, $size in $breakpoints {
    @include from($name) {
      .#{$name}\:px-no {
        padding-left: 0;
        padding-right: 0;
      }
      .#{$name}\:py-no {
        padding-top: 0;
        padding-bottom: 0;
      }
      .#{$name}\:px-#{$i * _strip-unit($margin-size)} {
        padding-left: $margin-size * $i;
        padding-right: $margin-size * $i;
      }
      .#{$name}\:py-#{$i * _strip-unit($margin-size)} {
        padding-top: $margin-size * $i;
        padding-bottom: $margin-size * $i;
      }

      .#{$name}\:pb-no { padding-bottom: 0; }
      .#{$name}\:pb-#{$i * _strip-unit($margin-size)} { padding-bottom: $margin-size * $i; }

      .#{$name}\:pt-no { padding-top: 0; }
      .#{$name}\:pt-#{$i * _strip-unit($margin-size)} { padding-top: $margin-size * $i; }

      .#{$name}\:pl-no { padding-right: 0; }
      .#{$name}\:pr-#{$i * _strip-unit($margin-size)} { padding-right: $margin-size * $i; }
      
      .#{$name}\:pr-no { padding-left: 0; }
      .#{$name}\:pl-#{$i * _strip-unit($margin-size)} { padding-left: $margin-size * $i; }
    }
  }
}

/* Grid
---------------------------------------------------------------------------- */
// $_grid-gutter-half:    $grid-gutter * .5;
// $_gutter-compensation: $grid-gutter * .5 * -1;

.row {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;

  min-width: 100%;

  $gutter: $min-grid-gutter;
  
  margin-left: $gutter * .5 * -1;
  margin-right: $gutter * .5 * -1;

  @each $name, $size in $grid-gutter {
    @if map-has-key($breakpoints, $name) {
      $gutter: map-get($grid-gutter, $name);

      @include from($name) {
        margin-left: $gutter * .5 * -1;
        margin-right: $gutter * .5 * -1;
      }
    }
  }
}

@mixin col-common {
  box-sizing: border-box;
  flex: 1 0 auto;

  $gutter: $min-grid-gutter;
  
  padding-left: $gutter * .5;
  padding-right: $gutter * .5;

  @each $name, $size in $grid-gutter {
    @if map-has-key($breakpoints, $name) {
      $gutter: map-get($grid-gutter, $name);

      @include from($name) {
        padding-left: $gutter * .5;
        padding-right: $gutter * .5;
      }
    }
  }
}

.col {
  @include col-common;
  max-width: 100%;
}
@for $i from 1 through $grid-columns {
  .col-#{$i} {
    @include col-common;
    flex-basis: 100% / $grid-columns * $i;
    max-width: 100% / $grid-columns * $i;
  }
}
@for $i from 0 through $grid-columns {
  .offset-#{$i} {
    @include col-common;
    @if $i == 0 {
      margin-left: 0;
    } @else {
      margin-left: 100% / $grid-columns * $i;
    }
  }
}

@each $name, $size in $breakpoints {
  @include from($name) {
    .#{$name}\:col {
      @include col-common;
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
    }

    @for $i from 1 through $grid-columns {
      .#{$name}\:col-#{$i} {
        @include col-common;
        flex-basis: 100% / $grid-columns * $i;
        max-width: 100% / $grid-columns * $i;
      }
    }

    @for $i from 0 through $grid-columns {
      .#{$name}\:offset-#{$i} {
        @if $i == 0 {
          margin-left: 0;
        } @else {
          margin-left: 100% / $grid-columns * $i;
        }
      }
    }
  }
}

/* Dispaly util
---------------------------------------------------------------------------- */
.hidden { display: none; }
.block { display: block; }
.inline { display: inline; }
.inline-block { display: inline-block; }
.inline-flex { display: inline-flex; }
.flex { display: flex; }
.table { display: table; }

@each $name, $size in $breakpoints {
  @include from($name) {
    .#{$name}\:hidden { display: none; }
    .#{$name}\:block { display: block; }
    .#{$name}\:inline { display: inline; }
    .#{$name}\:inline-block { display: inline-block; }
    .#{$name}\:inline-flex { display: inline-flex; }
    .#{$name}\:flex { display: flex; } 
    .#{$name}\:table { display: table; }
  } 
}

/* Overflow util
---------------------------------------------------------------------------- */
.overflow-auto {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.overflow-hidden { overflow: hidden; }
.overflow-x-hidden { overflow-x: hidden; }
.overflow-x-auto {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.overflow-y-hidden { overflow-y: hidden; }
.overflow-y-auto {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@each $name, $size in $breakpoints {
  @include from($name) {
    .#{$name}\:overflow-auto {
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
    .#{$name}\:overflow-hidden { overflow: hidden; }
    .#{$name}\:overflow-x-hidden { overflow-x: hidden; }
    .#{$name}\:overflow-x-auto {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .#{$name}\:overflow-y-hidden { overflow-y: hidden; }
    .#{$name}\:overflow-y-auto {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
}

/* Position util
---------------------------------------------------------------------------- */
.static { position: static; }
.fixed { position: fixed; }
.absolute { position: absolute; }
.relative { position: relative; }
.sticky { position: sticky; }
.pin-t { top: 0; }
.pin-r { right: 0; }
.pin-b { bottom: 0; }
.pin-l { left: 0; }
.pin-y {
  top: 0;
  bottom: 0;
}
.pin-x {
  right: 0;
  left: 0;
}
.pin {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.pin-none {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}

@each $name, $size in $breakpoints {
  @include from($name) {
    .#{$name}\:static { position: static; }
    .#{$name}\:fixed { position: fixed; }
    .#{$name}\:absolute { position: absolute; }
    .#{$name}\:relative { position: relative; }
    .#{$name}\:sticky { position: sticky; }
    .#{$name}\:pin-t { top: 0; }
    .#{$name}\:pin-r { right: 0; }
    .#{$name}\:pin-b { bottom: 0; }
    .#{$name}\:pin-l { left: 0; }
    .#{$name}\:pin-y {
      top: 0;
      bottom: 0;
    }
    .#{$name}\:pin-x {
      right: 0;
      left: 0;
    }
    .#{$name}\:pin {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    .#{$name}\:pin-none {
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
    }
  }
}

/* Z-index util
---------------------------------------------------------------------------- */
.z-0 { z-index: 0; }
.z-10 { z-index: 10; }
.z-20 { z-index: 20; }
.z-30 { z-index: 30; }
.z-40 { z-index: 40; }
.z-50 { z-index: 50; }
.z-max { z-index: 2147483647; }
.z-auto { z-index: auto; }

@each $name, $size in $breakpoints {
  @include from($name) {
    .#{$name}\:z-0 { z-index: 0; }
    .#{$name}\:z-10 { z-index: 10; }
    .#{$name}\:z-20 { z-index: 20; }
    .#{$name}\:z-30 { z-index: 30; }
    .#{$name}\:z-40 { z-index: 40; }
    .#{$name}\:z-50 { z-index: 50; }
    .#{$name}\:z-max { z-index: 2147483647; }
    .#{$name}\:z-auto { z-index: auto; }
  }
}

/* Flex
---------------------------------------------------------------------------- */
.flex-no-wrap { flex-wrap: nowrap; }
.flex-wrap { flex-wrap: wrap; }

.flex-column { flex-direction: column; }

.content-start { align-content: flex-start; }
.content-center { align-content: center; }
.content-end { align-content: flex-end; }
.content-between { align-content: space-between; }
.content-around { align-content: space-around; }

.justify-start { justify-content: flex-start; }
.justify-center { justify-content: center; }
.justify-end { justify-content: flex-end; }
.justify-between  { justify-content: space-between; }
.justify-around { justify-content: space-around; }

.items-stretch { align-items: stretch; }
.items-start { align-items: flex-start; }
.items-center { align-items: center; }
.items-end { align-items: flex-end; }
.items-baseline { align-items: baseline; }

.self-auto { align-self: auto; }
.self-start { align-self: flex-start; }
.self-center { align-self: center; }
.self-end { align-self: flex-end; }
.self-stretch { align-self: stretch; }

@each $name, $size in $breakpoints {
  @include from($name) {
    .#{$name}\:flex-no-wrap { flex-wrap: nowrap; }
    .#{$name}\:flex-wrap { flex-wrap: wrap; }

    .#{$name}\:content-start { align-content: flex-start; }
    .#{$name}\:content-center { align-content: center; }
    .#{$name}\:content-end { align-content: flex-end; }
    .#{$name}\:content-between { align-content: space-between; }
    .#{$name}\:content-around { align-content: space-around; }

    .#{$name}\:justify-start { justify-content: flex-start; }
    .#{$name}\:justify-center { justify-content: center; }
    .#{$name}\:justify-end { justify-content: flex-end; }
    .#{$name}\:justify-between  { justify-content: space-between; }
    .#{$name}\:justify-around { justify-content: space-around; }

    .#{$name}\:items-stretch { align-items: stretch; }
    .#{$name}\:items-start { align-items: flex-start; }
    .#{$name}\:items-center { align-items: center; }
    .#{$name}\:items-end { align-items: flex-end; }
    .#{$name}\:items-baseline { align-items: baseline; }

    .#{$name}\:self-auto { align-self: auto; }
    .#{$name}\:self-start { align-self: flex-start; }
    .#{$name}\:self-center { align-self: center; }
    .#{$name}\:self-end { align-self: flex-end; }
    .#{$name}\:self-stretch { align-self: stretch; }
  }
}

/* Width util
---------------------------------------------------------------------------- */
.w-auto { width: auto; }
.w-full { width: 100%; }
.w-screen { width: 100vw; }
.w-1\/2 { width: 50%; }
.max-w-full { max-width: 100%; }
.max-w-screen { max-width: 100vw; }
.min-w-full { min-width: 100%; }
.min-w-screen { min-width: 100vw; }

@each $name, $size in $breakpoints {
  @include from($name) {
    .#{$name}\:w-auto { width: auto; }
    .#{$name}\:w-full { width: 100%; }
    .#{$name}\:w-screen { width: 100vw; }
    .#{$name}\:w-1\/2 { width: 50%; }
    .#{$name}\:max-w-full { max-width: 100%; }
    .#{$name}\:max-w-screen { max-width: 100vw; }
    .#{$name}\:min-w-full { min-width: 100%; }
    .#{$name}\:min-w-screen { min-width: 100vw; }
  }
}

/* Height util
---------------------------------------------------------------------------- */
.h-full { height: 100%; }
.h-screen { height: 100vh; }
.max-h-full { max-height: 100%; }
.max-h-screen { max-height: 100vh; }
.min-h-full { min-height: 100%; }
.min-h-screen { min-height: 100vh; }

@each $name, $size in $breakpoints {
  @include from($name) {
    .#{$name}\:h-full { height: 100%; }
    .#{$name}\:h-screen { height: 100vh; }
    .#{$name}\:max-h-full { max-height: 100%; }
    .#{$name}\:max-h-screen { max-height: 100vh; }
    .#{$name}\:min-h-full { min-height: 100%; }
    .#{$name}\:min-h-screen { min-height: 100vh; }
  }
}

/* Pointer
---------------------------------------------------------------------------- */
.events-none { pointer-events: none; }
.events-auto { pointer-events: auto; }

@each $name, $size in $breakpoints {
  @include from($name) {
    .#{$name}\:events-none { pointer-events: none; }
    .#{$name}\:events-auto { pointer-events: auto; }
  }
}

/* Select
---------------------------------------------------------------------------- */
.select-none { user-select: none; }
.select-text { user-select: text; }

@each $name, $size in $breakpoints {
  @include from($name) {
    .#{$name}\:select-none { user-select: none; }
    .#{$name}\:select-text { user-select: text; }
  }
}

/* Text
---------------------------------------------------------------------------- */
.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }
.text-justify { text-align: justify; }

@each $name, $size in $breakpoints {
  @include from($name) {
    .#{$name}\:text-left { text-align: left; }
    .#{$name}\:text-center { text-align: center; }
    .#{$name}\:text-right { text-align: right; }
    .#{$name}\:text-justify { text-align: justify; }
  }
}

/* Whitespace
---------------------------------------------------------------------------- */
.wspace-normal { white-space: normal; }
.wspace-no-wrap { white-space: nowrap; }
.wspace-pre { white-space: pre; }
.wspace-pre-line { white-space: pre-line; }
.wspace-pre-wrap { white-space: pre-wrap; }

@each $name, $size in $breakpoints {
  @include from($name) {
    .#{$name}\:wspace-normal { white-space: normal; }
    .#{$name}\:wspace-no-wrap { white-space: nowrap; }
    .#{$name}\:wspace-pre { white-space: pre; }
    .#{$name}\:wspace-pre-line { white-space: pre-line; }
    .#{$name}\:wspace-pre-wrap { white-space: pre-wrap; }
  }
}

/* Truncate
---------------------------------------------------------------------------- */
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Text reset
---------------------------------------------------------------------------- */
.text-reset {
  font: 0/0 a;
}


/* Text transfomr
---------------------------------------------------------------------------- */
.uppercase { text-transform: uppercase; }
.lowercase { text-transform: lowercase; }

@each $name, $size in $breakpoints {
  @include from($name) {
    .#{$name}\:uppercase { text-transform: uppercase; }
    .#{$name}\:lowercase { text-transform: lowercase; }
  }
}

/* Letter spacing
---------------------------------------------------------------------------- */
.tracking-tight { letter-spacing: -0.05em; }
.tracking-normal { letter-spacing: 0; }
.tracking-wide { letter-spacing: 0.15em; }

@each $name, $size in $breakpoints {
  @include from($name) {
    .#{$name}\:tracking-tight { letter-spacing: -0.05em; }
    .#{$name}\:tracking-normal { letter-spacing: 0; }
    .#{$name}\:tracking-wide { letter-spacing: 0.15em; }
  }
}

/* Fonts
---------------------------------------------------------------------------- */
.font-bold { font-weight: bold; }
.font-normal { font-weight: normal; }

/* Cursor
---------------------------------------------------------------------------- */
.cursor-pointer { cursor: pointer; }
.cursor-default { cursor: default; }

@each $name, $size in $breakpoints {
  @include from($name) {
    .#{$name}\:cursor-pointer { cursor: pointer; }
    .#{$name}\:cursor-default { cursor: default; }
  }
}

@each $name, $size in $breakpoints {
  @include from($name) {
    .#{$name}\:font-bold { font-weight: bold; }
    .#{$name}\:font-normal { font-weight: normal; }
  }
}

body:after {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  width: 1px;
  height: 1px;
  margin: -1px 0 0 -1px;
  position: absolute;
  left: -1px;
  top: -1px;
}

body:after {
  content: 'all';

  @each $name, $size in $breakpoints {
    @include from($name) { content: "#{$name}"; }
  }
}

head {
  font-family: 'all';

  @each $name, $size in $breakpoints {
    @include from($name) { font-family: "#{$name}"; }
  }
}

body::before {
  @if $debug {  
    content: 'all';
    display: block;
    position: fixed;
    right: 5px;
    bottom: 5px;
    padding: 0 20px;
    background-color: black;
    z-index: 9999;
    line-height: 25px;
    font-size: 11px;
    color: #ccc;
    font-family: monospace;

    @each $name, $size in $breakpoints {
      @include from($name) { content: "#{$name} :: #{$size}"; }
    }
  }
}