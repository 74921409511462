@charset 'UTF-8';

$path: '/assets/fonts';

@font-face {
  font-family: 'GraphikLC-Medium';
  src: url('#{$path}/GraphikLC-Medium.woff2') format('woff2'),
    url('#{$path}/GraphikLC-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TRYVesterbro-LightItalic';
  src: url('#{$path}/Vesterbro-Light_Italic-Web.woff2') format('woff2'),
    url('#{$path}/Vesterbro-Light_Italic-Web.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Vesterbro-Light';
  src: url('#{$path}/Vesterbro-Light-Web.woff2') format('woff2'),
    url('#{$path}/Vesterbro-Light-Web.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* purgecss start ignore */
*,
*:before,
*:after {
  text-rendering: optimizeLegibility;

  -webkit-font-smoothing: antialiased;
      font-smoothing: antialiased;
}

.ff-main { font-family: 'Vesterbro-Light'; }
.ff-italic { font-family: 'TRYVesterbro-LightItalic'; }
.ff-graphik { font-family: 'GraphikLC-Medium'; }

body, html { @extend .ff-main; }
/* purgecss end ignore */